<template>

</template>

<script>
export default {
  name: "DataPlatform",
  data(){
    return{

    }
  },
  mounted() {
    this.gotoCpisFrontEnd();
  },
  methods:{
    gotoCpisFrontEnd:function (){
      window.open('https://www.hunancpc.net/cpis-admin/',"_blank");
    }
  }
}
</script>

<style scoped>

</style>